<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4">
                <v-card>
                    <div>
                        <Avatar
                            url="img/upload"
                            :nome="eu.atributos.nome_completo"
                            :cargo="eu.atributos.parceiro.colaborador.cargo.nome"
                        />
                    </div>
                    <v-divider></v-divider>
                    <v-card-text class="text-center pa-7">
                        <h6 class="subtitle-2">"{{ descricao }}"</h6>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="8">
                <v-card>
                    <v-card-text>
                        <h2 class="subtitle-5">Informações do {{ nome }}</h2>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field v-model="nome" label="Nome" outlined dense></v-text-field>
                        <v-text-field type="email" v-model="email" label="E-mail" outlined dense></v-text-field>
                        <v-text-field v-model="login" disabled label="Login" outlined dense></v-text-field>
                        <v-text-field
                            v-model="senha"
                            outlined
                            dense
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            label="Senha"
                            @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-textarea outlined v-model="descricao" label="Sobre mim" dense />
                        <v-btn class="text-capitalize mt-5 element-0" color="success">Salvar</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import ApiService from '@/services/ApiService.js';
import Avatar from '@/components/Upload/Avatar.vue';

export default {
    components: {
        Avatar
    },
    name: 'Profile',
    mounted() {
        this.getEu();
    },
    ...mapGetters({
        avatar: 'autenticacao/getAvatar'
    }),
    methods: {
        async getEu() {
            await ApiService({
                method: 'get',
                url: `V1/eu`,
                data: {}
            })
                .then(resposta => {
                    this.eu = resposta.data.data;
                    this.nome = this.eu.atributos.nome_completo;
                })
                .catch(erro => {
                    throw new Error(erro.message);
                });
        }
    },

    data: () => ({
        eu: {},
        nome: '',
        login: '',
        descricao: '',
        email: '',
        senha: '',
        show1: false,
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            emailMatch: () => "The email and password you entered don't match"
        },
        items: ['London', 'India', 'America']
    })
};
</script>
